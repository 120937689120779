body {
    margin: 0;
    padding: 0;
}
.row {
	margin-left: 0;
	margin-right: 0;
}
.clear {
  clear: both;
}
hr {
	border-top: 1px solid #fff;
}
.hr-2 {
	border-top: 1px solid #748fbc;
	margin: 30px 0;
}
.btn {
	color: #4f5962;	
	font-family: 'Bree Serif', serif;
	font-size: 20px;
}
.btn:hover {
	color: #fff;
	text-decoration: none;
}
h1 {
    font-size: 40px; 
    line-height: 42px;
	margin-top: 0px;
	margin-bottom: 20px;
	color: #748fbc;
    font-family: 'Lobster', cursive;
		small {
			font-size: 75%;
			line-height: 18px;
			font-weight: 1000;
			color: #4f5962;
			font-family: 'Open Sans', sans-serif;
		}  
}
h2 {
    color: #748fbc; 
	margin-top: 0px;
	font-size: 30px;
    font-family: 'Lobster', cursive;
}
h3 {
    color: #748fbc; 
	font-size: 20px;
    font-family: 'Lobster', cursive;
}
p {
	font-size: 16px;
	line-height: 24px;
	color: #333333;
	font-family: 'Libre Baskerville', serif;
		a:link {
			color: #e0443d;
			text-decoration: none;
			font-weight: 600;
		}
		a:visited {
			color: #e0443d;
			text-decoration: none;
		}
		a:hover {
			color: #748fbc;
			text-decoration: none;
		}
		a:active {
			color: #e0443d;
			text-decoration: none;
		}
		small {
			font-size: 75%;
		}
}
ul {
	li {
	    font-size: 16px;
	    line-height: 24px;
	    color: #333333;
	    font-family: 'Libre Baskerville', serif;
	    font-weight: 300; 
       a:link {
			    color: #e0443d;
			    text-decoration: none;
			    font-weight: 600;
		    }
		    a:visited {
			    color: #e0443d;
			    text-decoration: none;
		    }
		    a:hover {
			    color: #748fbc;
			    text-decoration: none;
		    }
		    a:active {
			    color: #e0443d;
			    text-decoration: none;
		    } 
	}
}
footer {
    background-color: #000;
    padding: 60px 4% 40px 4%;
    .logo {
		max-width: 250px;
	}
    	a:link {
			color: #e0433b;
			text-decoration: none;
		}
		a:visited {
			color: #e0433b;
			text-decoration: none;
		}
		a:hover {
			color: #748fbc;
			text-decoration: none;
		}
		a:active {
			color: #e0433b;
			text-decoration: none;
		}  
	p, h3 {
		color: #fff; 
	}
	ul {
		li {
		    a:link {
			    color: #e0433b;
			    text-decoration: none;
			    font-weight: 600;
		    }
		    a:visited {
			    color: #e0433b;
			    text-decoration: none;
		    }
		    a:hover {
			    color: #748fbc;
			    text-decoration: none;
		    }
		    a:active {
			    color: #e0433b;
			    text-decoration: none;
		    }   
		}
	}
}
.navbar {
	background: #000;
	width: 100%;
	margin: 0 auto;
    display: table;
    table-layout: auto;
    float: none;
    .logo {
		max-width: 200px;
	}
    .phone {
		font-size: 16px;
		font-family: 'Libre Baskerville', serif;
		a {
			color: #fff;
			font-size: 28px;
			@media (min-width: 992px) and (max-width: 1199.98px) {
				font-size: 20px;
			}
		}
		a:hover {
			color: #748fbc;
			text-decoration: none;
		}
		@media (max-width: 767.98px) {
			padding-left: 0;
			padding-right: 0;
		}
	}
	.social {
		a {
			color: #e0443c;	
		}
		a:hover {
			color: #748fbc;
		}
	}
}
.navbar .navbar-nav li a{
    		color: #fff;
			font-size: 20px;
			font-family: 'Libre Baskerville', serif;
			font-weight: 600;
			padding: 30px;
			margin: 0;
			@media (min-width: 992px) and (max-width: 1199.98px) {
				font-size: 12px;
			}
			@media (max-width: 991.98px) {
				padding: 20px 0 10px 0;
			}
		}
.navbar-toggler {
	color: #e0443c;	
}
.navbar .navbar-nav > .active > a:hover{
	color: #748fbc;
	}	
.navbar .navbar-nav .active a {
	color: #e0443c;
	}
.navbar .navbar-nav li a:hover{
    color: #748fbc;
}
.carousel {
	.carousel-caption {
		left: 5%;
		bottom: 5%;
		h1 {
			color: #fff;
			text-shadow: 2px 2px 3px rgba(0,0,0,0.6);
		}
		@media (max-width: 575.98px) {
			h1 {
				display: none;
			}	
		}
		@media (min-width: 576px) and (max-width: 767.98px) { 
			h1 {
				font-size: 24px; 	
			}	
		}
		@media (min-width: 768px) and (max-width: 991.98px) {
			h1 {
				font-size: 34px; 	
			}
		}
		@media (min-width: 992px) and (max-width: 1199.98px) {
			h1 {
				font-size: 36px; 	
			}
		}
		@media (min-width: 1200px) { 
			h1 {
				font-size: 54px; 	
			}
		}
	}
}
.header-img {
	.container-fluid {
		position: relative;
		text-align: center;
		h1 {
			color: white;
			text-shadow: 2px 2px 3px rgba(0,0,0,0.6);
			@media (max-width: 767.98px) {
					font-size: 30px;
					line-height: 32px;
				}
		}
	}
	.centered {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}
.featured {
	.card {
		background-color: #e0433b;
		height: 100%;
		h2, p, ul, li {
			color: #000	
		}
		a:hover {
			text-decoration: none;	
		}
		img {
			max-width: 100px;	
			@media (max-width: 767.98px) { 
				max-width: 60px;
			}
		}
	}
	.card-2 {
		background-color: #bf342d;	
	}
  .overlay {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		height: 100%;
		width: 100%;
		opacity: 0;
		transition: .5s ease;
		background-color: #748fbc;
	}
	.card:hover .overlay {
		opacity: 1;
	}
	.text {
		color: white;
		font-size: 20px;
		font-family: 'Libre Baskerville', serif;
		position: absolute;
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		text-align: center;
	}	
}
main {
	padding: 60px 4%;	
	background-image: url("../images/cement-bg.jpg");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: #f6f6f6;
	border-bottom: 6px solid #748fbc;
	.truck {
		z-index: 1;
		position: relative;
		text-align: right;
		margin: -60px 0 -100px 0;
		max-width: 200px;
	}
	img {
		max-width: 40px;	
		}
	.card-header {
		background-color: #e0433b;	
		h2 {
			color: #000;	
		}
	}
	.card-body {
		background-color: #000;
		p {
			color: #fff;
			font-size: 20px;
			line-height: auto;
		} 
	}
	strong {
		font-size: 20px;	
	}
	.left {
		border-left: 3px dashed #748fbc;
	}
	.right {
		border-right: 3px dashed #748fbc;
	}
}
.map {
	position: relative; 
	z-index: 0;
}